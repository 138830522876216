<template>
  <div
    class="ChallengeInvitationView"
    :class="{ processing }">
    <div class="me">
      <div
        class="background"
        :style="{ backgroundColor: me.bgColor }" />
      <svg
        class="slantedEdge meSlantedEdge"
        xheight="10%"
        xwidth="100%"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <polygon
          points="0,100 0,0 100,0"
          :fill="me.bgColor" />
      </svg>
      <div class="xxx">
        <div
          class="Avatar"
          :style="{ backgroundImage: `url(${me.avatar})` }" />
        <div class="name">
          {{ me.name }}
        </div>
      </div>
    </div>
    <div class="you">
      <div
        class="background"
        :style="{ backgroundColor: you.bgColor }" />
      <svg
        class="slantedEdge youSlantedEdge"
        xheight="10%"
        xwidth="100%"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <polygon
          points="0,100 100,0 100,100"
          :fill="you.bgColor" />
      </svg>
      <div class="xxx">
        <div class="Avatar" />
        <div class="name">
          {{ you.name }}
        </div>
      </div>
    </div>
    <div
      class="vs"
      :style="{ backgroundColor: me.bgColor }"
      @click="start()">
      Vs
    </div>
  </div>
</template>

<script>
import store from '@/store';
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    props: {
        gameId: {
            type: String,
            required: true
        },
        challengeId: {
            type: String,
            required: true
        },
        groupInvitationId: {
            type: String,
            required: true
        }
    },
    inject: ['theme'],
    data() {
        return {
            processing: false
        };
    },
    computed: {
        me() {
            return {
                bgColor: '#347a9e', // this.theme.background,
                name: 'My name',
                avatar: this.$store.state.moduleAuth.profile.avatar
            };
        },
        you() {
            return {
                bgColor: '#12a4d6',
                name: 'Opponent name',
                avatar: ''
            };
        },
        groupInvitation() {
            return this.$store.getters['moduleSocial/getGroupInvitation'](this.groupInvitationId);
        }
    },
    methods: {
        async start() {
            this.processing = true;
            const groupId = this.groupInvitation.groupId;
            // await this.$store.dispatch('moduleSocial/deleteGroupInvitation', { groupInvitationId: this.groupInvitation.id })
            await this.$store.dispatch('moduleSocial/createMember', { groupId: groupId });
            // this.$router.push(`/social/group/${this.groupInvitation.groupId}`)
            gameHelper.createChallengeSession({
                challengeId: this.challengeId,
                navigate: true
            });
        }
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch('moduleSocial/refresh');
        await store.dispatch('moduleSocial/fetchGroupInvitation', { groupInvitationId: to.params.groupInvitationId });
        const challenge = store.getters['moduleSocial/getChallenge'](to.params.challengeId);
        if (challenge) {
            const group = store.getters['moduleSocial/getGroup'](challenge.groupId);
            if (group) {
                // User is already a member, navig to challenge result view
                if (group.membersByGroupIdList.find(member => member.userId === store.state.moduleSocial.userId)) {
                    return next({
                        name: 'MemogeniusGame-ChallengeResult',
                        params: {
                            gameId: to.params.gameId,
                            challengeId: to.params.challengeId
                        }
                    });
                }
            }
        }
        next(); // NICE, user is not a member
    }
};
</script>

<style scoped lang="scss">
$sideMargin: 2em;
$transitionDuration: 1s;

$slantHeight: 5em;

.ChallengeInvitationView {
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    &.processing {
        .me {
            transition: transform $transitionDuration;
            transform: translateY(calc(-100% - #{$slantHeight}));
        }
        .you {
            transition: transform $transitionDuration;
            transform: translateY(calc(100% + #{$slantHeight}));
        }

        .vs {
            transition: transform $transitionDuration, opacity $transitionDuration;
            transform: scale(2);
            opacity: 0;
        }
    }
}

.you,
.me {
    position: relative;
    flex: 1;
    align-self: stretch;

    display: flex;
    align-items: center;
}

.me {
    // background-color: rgba(black, 0.2);
    .background {
        top: 0;
        bottom: $slantHeight / 2;
    }
    .slantedEdge {
        bottom: -$slantHeight / 2;
    }
    .xxx {
        justify-content: flex-start;
    }
    .Avatar {
        margin-right: 1em;
    }
}

.you {
    .background {
        bottom: 0;
        top: $slantHeight / 2;
    }
    .slantedEdge {
        top: -$slantHeight / 2;
    }
    .xxx {
        // justify-content: flex-end;
        flex-direction: row-reverse;
    }
    .Avatar {
        margin-left: 1em;
    }
}

.background {
    position: absolute;
    width: 100%;
}

.slantedEdge {
    width: 100%;
    height: $slantHeight;
    position: absolute;
}
.meSlantedEdge {
    bottom: 0;
    left: 0;
}

.youSlantedEdge {
    top: 0;
    left: 0;
}

.vs {
    $size: 3.5em;
    position: absolute;
    width: $size;
    height: $size;
    background-color: blue;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.xxx {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 2em;
}
.Avatar {
    $size: 5em;
    width: $size;
    height: $size;
    background-color: gray;
    border-radius: 50%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.name {
    font-size: 110%;
    font-weight: 600;
}
</style>
